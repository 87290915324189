module.exports = {
  system: {
    changeLocale: "Switch Language",
    create: "Create",
    import: "Import",
    delete: "Delete",
    edit: "Edit", 
    detail: "Detail",
    index: "Index",
    operation: "Operation",
    search: "Search",
    reset: "Reset",
    confirm: "Confirm",
    cancel: "Cancel",
    pleaseInput: "Please Input ",
    pleaseSelect: "Please Select ",
    inputAndSearch: "Input and search",
    success:"success",
    fail:"fail",
    continue:"Continue",
    enable:"Enable",
    disable:"Disable",
    emptySelection:"Please select a record",
    option:"Option",
    closeOthers:"Close Others",
    closeAll:"Close All",
    login:"Login",
    logout:"Logout",
    loginSuccess:"Login success",
    loginFail:"Login fail, no authority",
    yes:"Yes",
    no:"No"
  },
  validate:{
    pwdLength:"Should be 6-20 characters long",
    pwdReg:"Letters or numbers only",
    pwdNotSame:"Password not same"
  },
  user: {
    account: "Account",
    name: "Name",
    password: "Password",
    password_confirm: "Password Confirm",
    nickName: "Nick Name",
    birthday: "Birthday",
    status:"Status",
    sex: "Sex",
    email: "E-mail",
    mobile: "Mobile",
    tel: "Tel",
    organization: "Organization",
    jobNumber: "Job Number",
    position: "Position",
    createUser: "Create User",
    basicInfo: "Basic Info",
    employeeInfo: "Employee Info",
    createAccount: "Create User",
    editAccount: "Edit User",
    male: "Male",
    female: "Female",
    createSuccess:"Account create successfully",
    updateSuccess:"Account update successfully",
    resetPassword:"Reset Password",
    resetPasswordSuccess:"Reset password successfully",
    enableSuccess:"Account enabled",
    disableSuccess:"Account disabled",
    modifyPwd:"Modify Password",
    orgPwd:"Orign Password",
    newPwd:"New Password",
    pwdConfirm:"Confirm Password"
  },
  role: {
    name: "Role Name",
    system: "System Name",
    userBinded: "User Binded",
    bindUser: "Bind User",
    createRole: "Create Role",
    updateRole: "Update Role",
    sourceCode: "Source Code",
    sourceName: "Button Name",
    account: "Account",
    userName: "Name",
    organization: "Organization",
    filter: "Input the filter",
    unBind: "User UnBind",
    binded: "User Binded",
    status:"Status",
    createSuccess:"Create role successfully",
    updateSuccess:"Update role successfully",
    bindSuccess:"Bind user successfully",
  },
  customer:{
    isActive:"Is active",
    address:"Address",
    province:"Province/State",
    provinceNameCn:"Province/State CN",
    provinceNameEn:"Province/State EN",
    sale:"Sale",
    city:"City",
    cityNameCn:"City Name CN",
    cityNameEn:"City Name EN",
    closing:"Settlement",
    name:"Company name CN/EN",
    companyCode:"Company code",
    companyNameCn:"Company name CN",
    companyNameEn:"Company name EN",
    country:"Country",
    countryNameCn:"Country CN",
    countryNameEn:"Country EN",
    creditLimit:"Credit limit",
    customerService:"Customer service",
    email:"Email",
    type:"Type",
    mobile:"Mobile",
    organization:"Organization",
    postcode:"Post code",
    settlementMethod:"Settlement method",
    createCustomer:"Create customer",
    editCustomer: "Edit customer",
    createSuccess:"Customer create successfully",
    updateSuccess:"Customer update successfully",
  },
  contract:{
    contractName:"Contract name",
    contractNo:"Contract No",
    contractPayerCode:"Party A company code",
    contractPayerName:"Party A company name",
    contractPayeeCode:"Party B company code",
    contractPayeeName:"Party B company name",
    contractType:"Contract type",
    startDate:"Start date",
    endDate:"End date",
    partyA:"Party A",
    partyB:"Party B",
    effectDate:"Effect date",
    createContract:"Create contract",
    updateContract:"Edit contract",
    createSuccess:"Create contract successfully",
    updateSuccess:"Update contract successfully",
  },
  harbour: {
    countryCode: "Country Code",
    atThreeCode: "AT Three Code",
    atName: "AT Name CH",
    atNameEn: "AT Name EN",
    stFiveCode: "Sea Transprot Code",
    lineCode: "Other Line",
    remark: "Remark",
    createHabour: "Create Harbour",
    updateHarbour: "Edit Harbour",
    createSuccess:"Create harbour successfully",
    updateSuccess:"Update harbour successfully",
  },
  rate:{
    originalCurrency:"Original Currency",
    targetCurrency:"Target Currency",
    effectiveDate:"Effective Date",
    expiryDate:"Expiry Date",
    exchangeRate:"Exchange Rate",
    organization:"Organization",
    remark:"remark",
    createRate: "Create Rate",
    updateRate: "Update Rate",
    createSuccess:"Create rate successfully",
    updateSuccess:"Update rate successfully",
  },
  chargeGroup:{
    code:"Group Code",
    name:"Group Name",
    organization:"Organization",
    remark:"remark",
    createChargeGroup: "Create Charge Group",
    updateChargeGroup: "Update Charge Group",
    createSuccess:"Create charge group successfully",
    updateSuccess:"Update charge group successfully",
  },
  charge:{
    code:"Code",
    group:"Group Name",
    nameEn:"Charge Name",
    nameCn:"Charge Name CH",
    organization:"Organization",
    remark:"remark",
    createCharge: "Create Charge",
    updateCharge: "Update Charge",
    createSuccess:"Create charge successfully",
    updateSuccess:"Update charge successfully",
  },
  organization: {
    name: "Organization Name",
    code: "Organization Code",
    city: "City",
    prefix: "Order Prefix",
    supOrganization: "Superior Organization",
    remark: "Remark",
    createOrganization: "Create Organization",
  },
  addressbook: {
    countryCode: "Country Code",
    countryName: "Country Name CH",
    country: "Country Name",
    remark: "Remark",
  },
  warehouse: {
    warehouseType: "Type",
    warehouseCode: "Code",
    companyName: "Company",
    consignee: "Consignee",
    consigneeAddress1: "Address 1",
    consigneeAddress2: "Address 2",
    consigneeAddress3: "Address 3",
    houseNumber: "House Number",
    country: "Country",
    provinceOrState: "Province/State",
    city: "City",
    postCode: "Post Code",
    contactName: "Contact Name",
    contactMobile: "Contact Tel",
    contactMail: "Contact Email",
    remark: "Remark",
    createWarehouse: "Create Warehouse",
    updateWarehouse: "Update Warehouse",
    createSuccess:"Create warehouse successfully",
    updateSuccess:"Update warehouse successfully",
  }
};