module.exports = {
  system: {
    changeLocale: "切换语言",
    create: "新增",
    import: "导入",
    delete: "删除",
    edit: "编辑",
    detail: "详情",
    index: "序号",
    operation: "操作", 
    search: "查询",
    reset: "重置",
    confirm: "确定",
    cancel: "取消",
    pleaseInput: "请输入",
    pleaseSelect: "请选择",
    inputAndSearch:"输入查询",
    success:"成功",
    fail:"失败",
    continue:"是否继续",
    enable:"启用",
    disable:"禁用",
    emptySelection:"请选择一条记录",
    option:"标签选项",
    closeOthers:"关闭其他",
    closeAll:"关闭所有",
    login:"登录",
    logout:"退出登录",
    loginSuccess:"登录成功",
    loginFail:"登录失败，没有权限",
    yes:"是",
    no:"否"
  },
  validate:{
    pwdLength:"请输入6-20位密码",
    pwdReg:"密码只能包含字母或数字",
    pwdNotSame:"两次输入的密码不一致"
  },
  user: {
    account: "账号",
    name: "姓名",
    password: "密码",
    password_confirm: "重复密码",
    nickName: "昵称",
    birthday: "生日",
    status:"状态",
    sex: "性别",
    email: "邮箱",
    mobile: "手机号",
    tel: "电话",
    organization: "所属机构",
    jobNumber: "工号",
    position: "职位",
    createUser: "新增用户",
    basicInfo: "基本信息",
    employeeInfo: "员工信息",
    createAccount: "新增用户",
    editAccount: "编辑用户",
    male:"男",
    female:"女",
    createSuccess:"用户创建成功",
    updateSuccess:"用户编辑成功",
    resetPassword:"重置密码",
    resetPasswordSuccess:"密码重置成功",
    enableSuccess:"用户已启用",
    disableSuccess:"用户已禁用",
    modifyPwd:"修改密码",
    orgPwd:"原密码",
    newPwd:"新密码",
    pwdConfirm:"密码确认"
  },
  role: {
    name: "角色名称",
    system: "所属系统",
    userBinded: "关联用户数",
    bindUser: "关联用户",
    createRole: "新增角色",
    updateRole: "编辑角色",
    sourceCode: "资源码",
    sourceName: "按钮名称",
    account: "账号",
    userName: "姓名",
    organization: "所属机构",
    filter: "输入关键字进行过滤",
    unBind: "未关联用户",
    binded: "已关联用户",
    status:"状态",
    createSuccess:"角色创建成功",
    updateSuccess:"角色编辑成功",
    bindSuccess:"关联用户成功",
  },
  customer:{
    isActive:"是否生效",
    address:"详细地址",
    province:"省/州",
    provinceNameCn:"省/州中文名",
    provinceNameEn:"省/州英文名",
    sale:"销售",
    city:"城市",
    cityNameCn:"城市中文名",
    cityNameEn:"城市英文名",
    closing:"结算",
    name:"公司名称(中文/英文)",
    companyCode:"公司代码",
    companyNameCn:"公司中文名",
    companyNameEn:"公司英文名",
    country:"国家",
    countryNameCn:"国家中文名",
    countryNameEn:"国家英文名",
    creditLimit:"信用额度",
    customerService:"客服",
    email:"邮箱",
    type:"服务商类型",
    mobile:"联系电话",
    organization:"机构",
    postcode:"邮政编码",
    settlementMethod:"结算方式",
    createCustomer:"新增客户信息",
    editCustomer: "编辑客户信息",
    createSuccess:"客户信息创建成功",
    updateSuccess:"客户信息编辑成功",
  },
  contract:{
    contractName:"合同名称",
    contractNo:"合同编号",
    contractPayerCode:"甲方公司编号",
    contractPayerName:"甲方公司名称",
    contractPayeeCode:"乙方公司编号",
    contractPayeeName:"乙方公司名称",
    contractType:"合同类型",
    startDate:"起始日期",
    endDate:"终止日期",
    partyA:"合同甲方",
    partyB:"合同乙方",
    effectDate:"生效日期",
    createContract:"新增合同",
    updateContract:"编辑合同",
    createSuccess:"合同创建成功",
    updateSuccess:"合同编辑成功",
  },
  harbour: {
    countryCode: "国家编码",
    atThreeCode: "空运三字码",
    atName: "空运中文名",
    atNameEn: "空运英文名",
    stFiveCode: "海运五字码",
    lineCode: "航线编码",
    remark: "备注",
    createHarbour: "新增港口",
    updateHarbour: "编辑港口",
    createSuccess:"港口创建成功",
    updateSuccess:"港口编辑成功",
  },
  rate:{
    originalCurrency:"原始币种",
    targetCurrency:"目标币种",
    effectiveDate:"生效日期",
    expiryDate:"失效日期",
    exchangeRate:"汇率",
    organization:"所属机构",
    remark:"备注",
    createRate: "新增汇率",
    updateRate: "编辑汇率",
    createSuccess:"汇率创建成功",
    updateSuccess:"汇率编辑成功",
  },
  chargeGroup:{
    code:"组编号",
    name:"组名称",
    organization:"所属机构",
    remark:"备注",
    createChargeGroup: "新增费用组",
    updateChargeGroup: "编辑费用组",
    createSuccess:"费用组创建成功",
    updateSuccess:"费用组编辑成功",
  },
  charge:{
    code:"编号",
    group:"费用组",
    nameEn:"费用名(英文)",
    nameCn:"费用名(中文)",
    organization:"所属机构",
    remark:"备注",
    createCharge: "新增费用",
    updateCharge: "编辑费用",
    createSuccess:"费用创建成功",
    updateSuccess:"费用编辑成功",
  },
  organization: {
    name: "机构名称",
    code: "机构编码",
    city: "所属城市",
    prefix: "订单号前缀",
    supOrganization: "上级机构",
    remark: "备注",
    createOrganization: "新增机构",
  },
  addressbook: {
    countryCode: "国家二字码",
    countryName: "国家中文名",
    country: "国家英文名",
    remark: "备注",
  },
  warehouse: {
    warehouseType: "仓库类型",
    warehouseCode: "仓库代码",
    companyName: "公司名",
    consignee: "收货人",
    consigneeAddress1: "收货人地址1",
    consigneeAddress2: "收货人地址2",
    consigneeAddress3: "收货人地址3",
    houseNumber: "门牌号",
    country: "国家",
    provinceOrState: "省/州",
    city: "城市",
    postCode: "邮政编码",
    contactName: "联系人",
    contactMobile: "联系人电话",
    contactMail: "联系人邮箱",
    remark: "备注",
    createWarehouse: "新增仓库地址",
    updateWarehouse: "编辑仓库地址",
    createSuccess:"仓库地址创建成功",
    updateSuccess:"仓库地址编辑成功",
  }
};